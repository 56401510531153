import React from 'react';
import PropTypes from 'prop-types';
import Container from './Container';
import SubscribeForm from './SubscribeForm';

import ccTitle from '../images/cc-banner-2-title.png';

function SubscribeBanner({ title, title2, backgroundImage }) {
  return (
    <section className={`banner ${backgroundImage}`}>
      <Container>
        <div className="subscribe-banner">
          <div className="title-container">
            {backgroundImage === 'ccmeaningful-banner' ? (
              <img src={ccTitle} alt="x" />
            ) : (
              <>
                {title && <h1 className="banner-title1">{title}</h1>}
                {title2 && <h3 className="banner-title2">{title2}</h3>}
              </>
            )}
          </div>
          <SubscribeForm />
        </div>
      </Container>
    </section>
  );
}

SubscribeBanner.propTypes = {
  title: PropTypes.string,
  title2: PropTypes.string,
  backgroundImage: PropTypes.string,
};

export default SubscribeBanner;
