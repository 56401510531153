import React from 'react';
import { useSearch } from '../utils/common';

export default function CustomPaginationItem({ page, type, originalElement, hrefTag }) {
  const search = useSearch();
  if (type === 'page') {
    return (
      <a
        rel="nofollow"
        href={page !== 1 ? `/${hrefTag}/${page}/${search}` : `/${hrefTag}/${search}`}
        onClick={(e) => {
          // 防止进入翻页器自定义事件
          e.stopPropagation();
        }}
      >
        {page}
      </a>
    );
  }
  return originalElement;
}
