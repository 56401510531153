/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import iconUser from '../images/user.png';
import iconEmail from '../images/email.png';
import iconEmailGray from '../images/email-gray.png';
import iconUserGray from '../images/user-gray.png';

const ComboInput = (props) => {
  const { icon = 'user', value, onChange, placeholder, theme = 'colorful' } = props || {};
  const iconMap = {
    user: iconUser,
    email: iconEmail,
    emailGray: iconEmailGray,
    userGray: iconUserGray,
  };

  const iconKey = theme === 'colorful' ? icon : `${icon}Gray`;
  return (
    <div className="subscribe-form-item">
      {iconMap[iconKey] && (
        <div className="subscribe-form-item-icon">
          <img src={iconMap[iconKey]} alt="" />
        </div>
      )}

      <input
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        className="subscribe-form-input"
        placeholder={placeholder}
      />
    </div>
  );
};

ComboInput.propTypes = {
  icon: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  theme: PropTypes.string,
};

export default ComboInput;
