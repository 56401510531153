import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Spin } from 'antd';
import { getDataFromCpm } from '../utils/common';
import getCookie from '../utils/getCookie';
import ComboInput from './ComboInput';

const currentUuid = 'b116e15faa0645d4bb2498474f2ce999';
const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const SubscribeForm = (props) => {
  const { theme = 'colorful' } = props;
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState(null);
  const [utma, setUtma] = useState('');
  const [utmb, setUtmb] = useState('');

  const emailIcon = theme === 'colorful' ? 'email' : 'emailGray';
  const userIcon = theme === 'colorful' ? 'user' : 'userGray';
  const containerClass = theme === 'colorful' ? 'subscribe-form-colorful' : 'subscribe-form-plain';
  const buttonColor = theme === 'colorful' ? '#33D5BC' : '#2B75EB';
  const loadFormToken = async () => {
    try {
      const data = await fetch(`https://host.huiju.cool/formdata/get/${currentUuid}`).then((res) =>
        res.json()
      );
      setFormData(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadFormToken();
    const utma1 = getCookie('c__utma');
    const utmb1 = getCookie('c__utmb');
    setUtma(utma1);
    setUtmb(utmb1);
  }, []);
  const onFinish = async (values) => {
    if (!formData) {
      Modal.error({
        title: '服务出错，请刷新页面再重试',
        onOk() {},
      });
      return false;
    }
    setSubmitting(true);

    try {
      const fData = new FormData();
      Object.keys(values).forEach((key) => fData.append(key, values[key] || ''));
      fData.append('cltoken', formData.token);

      // 从 cpm 获取用户同意信息
      const enabledCustomerCookie = getDataFromCpm('网站必须');
      if (enabledCustomerCookie) {
        fData.append('utma', utma);
        fData.append('utmb', utmb);
      }

      const search = typeof window !== `undefined` ? window.location.search : '';

      const response = await fetch(`https://host.huiju.cool/form/${currentUuid}${search}`, {
        method: 'POST',
        body: fData,
      });

      if (!response.ok) {
        const res = await response.text();
        if (res === 'InvalidMobileCode') {
          throw new Error('您输入的验证码有误，请重新获取');
        }
        if (res === 'LimitSubmit') {
          throw new Error('请勿重复提交');
        }
        if (res === 'SubmitFull') {
          throw new Error('提交已达上限');
        }
        throw new Error('服务出错，请稍后再试');
      }

      form.resetFields();
      Modal.success({
        title: '您已成功提交申请，我们将根据您填写的信息与您取得联系',
        onOk: () => {
          //
        },
      });
    } catch (err) {
      Modal.error({ title: err.message });
    } finally {
      setSubmitting(false);
    }
    return true;
  };

  return (
    <div className={`subscribe-form ${containerClass}`}>
      <div className="form-title"> - 最新内容订阅 - </div>
      <Spin spinning={submitting}>
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          data-cl-attached="false"
          data-cl-id={currentUuid}
          size="small"
        >
          <Form.Item name="name" rules={[{ required: true, message: '请填写姓名' }]}>
            <ComboInput placeholder="请填写姓名(必填)" icon={userIcon} />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: '请填写邮箱' },
              { type: 'email', message: '邮箱填写有误' },
            ]}
          >
            <ComboInput placeholder="请填写邮箱(必填)" icon={emailIcon} />
          </Form.Item>
          <Form.Item className="button-row">
            <Button
              type="primary"
              htmlType="submit"
              block
              style={{ height: 45, backgroundColor: buttonColor, border: buttonColor }}
            >
              订阅内容
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

SubscribeForm.propTypes = {
  theme: PropTypes.string,
};

export default SubscribeForm;
