/* eslint-disable react/prop-types */
import React from 'react';
import { Row, Col, Pagination } from 'antd';
import { graphql } from 'gatsby';
import Container from '../components/Container';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import CustomPaginationItem from '../components/CustomPaginationItem';
import { useSearch } from '../utils/common';
import SubscribeBanner from '../components/SubscribeBanner';

function Article({ title, author, date, cover, description, path }) {
  return (
    <Row className="article" gutter={30}>
      <Col lg={{ span: 10 }} xs={{ span: 24 }} className="blog-image-wrapper">
        <img alt="cover" src={cover} className="img-fluid" />
      </Col>
      <Col className="articleDesc" lg={{ span: 14 }} xs={{ span: 24 }}>
        <div className="content">
          <div className="title">
            <a target="_blank" href={`${path}/`} rel="noreferrer">
              {title}
            </a>
          </div>
          <div className="description">{description}</div>
        </div>
        <div className="bottomSection">
          <div className="left">
            <div className="author">作者：{author}</div>
            <div className="date">{date}</div>
          </div>
          <div className="right">
            <a target="_blank" href={`${path}/`} rel="noreferrer">
              查看全文 &gt;
            </a>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ({ data, pageContext }) => {
  const { edges, totalCount, pageInfo } = data.allMdx;
  const pageType = pageContext.pageType || '';
  const isBlogs = pageType === 'blog';
  const search = useSearch();

  const hrefTag = isBlogs ? 'blogs' : pageContext.pageType;

  const handlePageChange = (page) => {
    location.href = page === 1 ? `/${hrefTag}/` : `/${hrefTag}/${page}/`;
  };

  return (
    <Layout current={pageType} subCurrent={isBlogs ? 'blogs' : pageType}>
      {isBlogs ? (
        <SubscribeBanner
          title="MarTech 增长笔记"
          title2="数智赋能每一个岗位"
          backgroundImage="growth-note-banner"
        />
      ) : (
        // <Banner
        //   title="MarTech 增长笔记"
        //   title2="数智赋能每一个岗位"
        //   backgroundImage="whitepaper-banner"
        // />
        <Banner title="行业洞察" title2="数智赋能每一个岗位" backgroundImage="whitepaper-banner" />
      )}

      <section className="resources">
        <Container>
          {edges.map(({ node }) => (
            <Article
              key={node.id}
              {...node.frontmatter}
              path={`/${pageContext.pageType}/${node.slug}${search}`}
            />
          ))}
          <div className="pagiContainer">
            <Pagination
              total={totalCount}
              pageSize={5}
              hideOnSinglePage
              showSizeChanger={false}
              current={pageInfo.currentPage}
              responsive
              onChange={handlePageChange}
              itemRender={(page, type, originalElement) => (
                <CustomPaginationItem
                  page={page}
                  type={type}
                  originalElement={originalElement}
                  hrefTag={hrefTag}
                />
              )}
            />
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export const blogListQuery = graphql`
  query blogListQuery($skip: Int, $limit: Int, $pageType: String) {
    allMdx(
      filter: { frontmatter: { type: { eq: $pageType } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          frontmatter {
            title
            author
            date
            cover
            description
          }
        }
      }
      totalCount
      pageInfo {
        currentPage
      }
    }
  }
`;
